import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { deleteUserAccount, cancelSubscription, smsEmailEnableDisable } from '../../Redux/actions/userAction';
import { confirmAlert } from 'react-confirm-alert';
import InitiateFreedom from '../Common/InitiateFreedom';
import { getToken } from '../../Helper/Session';
import Header from '../Layout/Header';
import { getOS } from '../../Helper/Util';

class Setting extends Component {

    handleAccountDelete = () => {
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            userid: token.id
        }
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure you want to delete your account?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.props.deleteUserAccount(params, this.props.history);
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    }


    handlePlanCancel = () => {
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            userid: token.id
        }
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to Cancel the subscription?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.props.cancelSubscription(params);
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    handleSMSEmailAlert = (e, type, status) => {
        e.preventDefault();
        let token = getToken() !== null ? JSON.parse(getToken()) : '';
        const params = {
            apikey: process.env.REACT_APP_API_KEY,
            userid: token.id,
            type: type,
            status: status === "1" ? "0" : "1"
        }
        let enDis = status === "1" ? 'By disabling' : 'By enabling';
        let email = status === "1" ? 'you will not receive any email alerts for new chat messages' : 'you will be receiving email notifications when all new/unopened chat messages';
        let sms = status === "1" ? 'you will not be receiving any Intiate Freedom alerts' : ' you will receive all Initiate Freedom alerts via SMS';
        let msg = type === 1 ? `${enDis} email notifications, ${email}.` : `${enDis} SMS notifications, ${sms}`;
        confirmAlert({
            title: 'Confirm to submit',
            message: `${msg}`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.props.smsEmailEnableDisable(params);
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    }


    render() {
        const { user: { user } } = this.props;
        let email_noti = user ? (user.enable_mail) : "0";
        let sms_noti = user ? (user.enable_sms) : "0";
        return (
            <>
                <section className="main-Section">
                    <Header
                        title={'Settings'}
                        backUrl={'dashboard'}
                    />
                    <div className="container-fluid midSection scrollSection">
                        <div className="content">
                            <InitiateFreedom />
                            <div className="usersList settingPage jcsb">
                                <ul>
                                    <h4 className="font-bold mb-0">Account</h4>
                                    <li><Link to="change-password"><i className="fas fa-lock"></i> Change Password</Link></li>
                                    {getOS() === 'iOS' &&
                                        <>
                                            <li className="switchItem">
                                                <Link to="#" onClick={(e) => this.handleSMSEmailAlert(e, 1, email_noti)}>
                                                    <span className="leftSideBlock">
                                                        <i class="fas fa-envelope"></i> Email Notification
                                                    </span>
                                                    <label className="switch">
                                                        <input
                                                            type="checkbox"
                                                            checked={email_noti === "1" ? true : false}
                                                        />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </Link>
                                            </li>
                                            <li className="switchItem">
                                                <Link to="#" onClick={(e) => this.handleSMSEmailAlert(e, 2, sms_noti)}>
                                                    <span className="leftSideBlock">
                                                        <i class="fas fa-sms"></i> SMS Notification
                                                    </span>
                                                    <label className="switch">
                                                        <input
                                                            type="checkbox"
                                                            checked={sms_noti === "1" ? true : false}
                                                        />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </Link>
                                            </li>

                                        </>
                                    }
                                    <h4 className="font-bold mt-25 mb-0">Support</h4>
                                    <li><Link to="about-us"><i className="fas fa-info-circle"></i> About Us</Link></li>
                                    <li><a href="https://www.gotyour6app.net/terms-of-use" target="_blank" rel="noreferrer"><i className="fas fa-file-alt"></i> Terms & Conditions</a></li>
                                    <li className="borderNone"><Link to="privacy-policy"><i className="fas fa-file-alt"></i> Privacy Policy</Link></li>
                                    <li className="borderNone"><a href={`mailto::help@gotyour6app.com`}><i className="fas fa-flag"></i> Report a Problem</a></li>
                                    <li className="borderNone"><Link to="subscription-info"><i className="fas fa-envelope-open-text"></i> Subscription Info</Link></li>
                                    <li><Link to="#" onClick={this.handleAccountDelete}><i className="fas fa-trash-alt"></i> Delete Account</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="subscribe-fixed" onClick={this.handlePlanCancel}>Cancel Subscription</div>
                </section>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user
})

const mapActionsToProps = {
    deleteUserAccount,
    cancelSubscription,
    smsEmailEnableDisable
}

export default connect(mapStateToProps, mapActionsToProps)(Setting);