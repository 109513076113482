import { GRP_REQUEST, DELETE_AFF, DELETE_LEGAL, GROUP_ACTION, CHANGE_POST_STATUS, DELETE_EVENT, DELETE_MEDIA, DELETE_ARTICLE, SET_PENDING_ARTICLE, SET_PENDING_EVENT, SET_PENDING_MEDIA, SET_FLAGGED, SET_USER_DETAIL, MEDIA_DETAIL, SET_REQUESTED_LIST, SET_PATRIOT_USER, SET_MY_GROUP, CLEAR_ERRORS, LOADING_UI, SET_GROUP, SET_LEGAL, SET_AFFILIATE, SET_EVENT, SET_ARTICLE, SET_MEDIA, ARTICLE_DETAIL } from '../types'
import axios from '../../Helper/Axios';
import { toast } from 'react-toastify';
import $ from "jquery";
import { currentTime } from '../../Helper/Util';

// Legal List

export const legalList = (params) => (dispatch) => {
    // dispatch({ type: LOADING_UI })
    axios.post('legal/legallist.php', params)
        .then(res => {
            if (res.data.status === '1') {
                dispatch({
                    type: SET_LEGAL,
                    payload: res.data.data,
                    hasmore: res.data.data.length >= 10 ? true : false,
                    currentPage: params.page
                });
            } else {
                dispatch({
                    type: SET_LEGAL,
                    payload: [],
                    hasmore: false,
                    currentPage: params.page
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Add Legal

export const addLegal = (params, history, page) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('legal/addlegal.php', params)
        .then(res => {
            if (res.data.status === '1') {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                history.push('legel-counsel');
                const param = {
                    apikey: process.env.REACT_APP_API_KEY,
                    page: page
                }
                dispatch(legalList(param))
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Delete Legal

export const deleteLegal = (params) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('feed/deletelegal.php', params)
        .then(res => {
            if (res.data.status === '1') {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                dispatch({
                    type: DELETE_LEGAL,
                    payload: params.legalid,
                });
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Affiliate List

export const affiliateList = (params) => (dispatch) => {
    // dispatch({ type: LOADING_UI })
    axios.post('legal/affiliatelist.php', params)
        .then(res => {
            if (res.data.status === '1') {
                dispatch({
                    type: SET_AFFILIATE,
                    payload: res.data.data,
                    hasmore: res.data.data.length >= 10 ? true : false,
                    currentPage: params.page
                });
            } else {
                dispatch({
                    type: SET_AFFILIATE,
                    payload: [],
                    hasmore: false,
                    currentPage: params.page
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Add Affiliates

export const addAffiliates = (params, history, page) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('legal/addaffiliate.php', params)
        .then(res => {
            if (res.data.status === '1') {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                history.push('affiliates-donation');
                const param = {
                    apikey: process.env.REACT_APP_API_KEY,
                    page: page
                }
                dispatch(affiliateList(param))
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Delete Affiliate

export const deleteAffiliate = (params) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('feed/deleteaffiliate.php', params)
        .then(res => {
            if (res.data.status === '1') {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                dispatch({
                    type: DELETE_AFF,
                    payload: params.affid,
                });
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Add Event

export const addEvent = (params, history, isAdmin, page) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('feed/addevent.php', params)
        .then(res => {
            if (res.data.status === '1') {
                if (isAdmin === '1') {
                    toast.success(res.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } else {
                    toast.success('Your Post is currently pending review by admin.Once approved it will show here.', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
                history.push('current-events');
                const param = {
                    apikey: process.env.REACT_APP_API_KEY,
                    page: page,
                    currenttimestamp: currentTime()
                }
                dispatch(eventList(param))
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Event List

export const eventList = (params) => (dispatch) => {
    // dispatch({ type: LOADING_UI })
    axios.post('feed/eventlist.php', params)
        .then(res => {
            if (res.data.status === '1') {
                dispatch({
                    type: SET_EVENT,
                    payload: res.data.data,
                    hasmore: res.data.data.length >= 10 ? true : false,
                    filter: false,
                    currentPage: params.page
                });
            } else {
                dispatch({
                    type: SET_EVENT,
                    payload: [],
                    hasmore: false,
                    filter: false,
                    currentPage: params.page
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Pending Event List

export const pendingEventList = (params) => (dispatch) => {
    // dispatch({ type: LOADING_UI })
    axios.post('feed/eventlist.php', params)
        .then(res => {
            if (res.data.status === '1') {
                dispatch({
                    type: SET_PENDING_EVENT,
                    payload: res.data.data,
                    hasmore: res.data.data.length >= 10 ? true : false,
                    filter: false
                });
            } else {
                dispatch({
                    type: SET_PENDING_EVENT,
                    payload: [],
                    hasmore: false,
                    filter: false
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

//  Filter Event List

export const filterEvents = (params) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('feed/filterevents.php', params)
        .then(res => {
            if (res.data.status === '1') {
                dispatch({
                    type: SET_EVENT,
                    payload: res.data.data,
                    hasmore: res.data.data.length >= 10 ? true : false,
                    filter: true
                });
            } else {
                dispatch({
                    type: SET_EVENT,
                    payload: [],
                    hasmore: false,
                    filter: true
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Delete event

export const deleteEvent = (params) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('feed/deleteevent.php', params)
        .then(res => {
            if (res.data.status === '1') {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                dispatch({
                    type: DELETE_EVENT,
                    payload: params.event_id,
                });
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Flag event

export const flagEvent = (params) => (dispatch) => {
    // dispatch({ type: LOADING_UI })
    axios.post('feed/flagevent.php', params)
        .then(res => {
            if (res.data.status === '1') {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Add Article

export const addArticle = (params, history, isAdmin, page) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('feed/addarticle.php', params)
        .then(res => {
            if (res.data.status === '1') {
                if (isAdmin === '1') {
                    toast.success(res.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } else {
                    toast.success('Your Post is currently pending review by admin.Once approved it will show here.', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
                history.push('articles-legislation');
                const param = {
                    apikey: process.env.REACT_APP_API_KEY,
                    page: page,
                }
                dispatch(articleList(param))
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Article List

export const articleList = (params) => (dispatch) => {
    // dispatch({ type: LOADING_UI })
    axios.post('feed/articlelist.php', params)
        .then(res => {
            if (res.data.status === '1') {
                dispatch({
                    type: SET_ARTICLE,
                    payload: res.data.data,
                    hasmore: res.data.data.length >= 10 ? true : false,
                    currentPage: params.page
                });
            } else {
                dispatch({
                    type: SET_ARTICLE,
                    payload: [],
                    hasmore: false,
                    currentPage: params.page
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Pending Article List

export const pendingArticleList = (params) => (dispatch) => {
    // dispatch({ type: LOADING_UI })
    axios.post('feed/articlelist.php', params)
        .then(res => {
            if (res.data.status === '1') {
                dispatch({
                    type: SET_PENDING_ARTICLE,
                    payload: res.data.data,
                    hasmore: res.data.data.length >= 10 ? true : false,
                    currentPage: params.page
                });
            } else {
                dispatch({
                    type: SET_PENDING_ARTICLE,
                    payload: [],
                    hasmore: false,
                    currentPage: params.page
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}


// Delete Article

export const deleteArticle = (params) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('feed/deletearticle.php', params)
        .then(res => {
            if (res.data.status === '1') {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                dispatch({
                    type: DELETE_ARTICLE,
                    payload: params.articleid,
                });
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Flag article

export const flagArticle = (params) => (dispatch) => {
    // dispatch({ type: LOADING_UI })
    axios.post('feed/flagarticle.php', params)
        .then(res => {
            if (res.data.status === '1') {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Add Media Post

export const addPost = (params, history, isAdmin, page) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('feed/addpost.php', params)
        .then(res => {
            if (res.data.status === '1') {
                if (isAdmin === '1') {
                    toast.success(res.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } else {
                    toast.success('Your Post is currently pending review by admin.Once approved it will show here.', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
                history.push('media');
                const param = {
                    apikey: process.env.REACT_APP_API_KEY,
                    page: page,
                }
                dispatch(mediaList(param));
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Edit Media Post

export const editPost = (params, history, page) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('feed/editpost.php', params)
        .then(res => {
            if (res.data.status === '1') {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                history.push('media');
                const param = {
                    apikey: process.env.REACT_APP_API_KEY,
                    page: page,
                }
                dispatch(mediaList(param));
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Media List

export const mediaList = (params) => (dispatch) => {
    // dispatch({ type: LOADING_UI })
    axios.post('feed/postlist.php', params)
        .then(res => {
            if (res.data.status === '1') {
                dispatch({
                    type: SET_MEDIA,
                    payload: res.data.data,
                    hasmore: res.data.data.length >= 10 ? true : false,
                    currentPage: params.page
                });
            } else {
                dispatch({
                    type: SET_MEDIA,
                    payload: [],
                    hasmore: false,
                    currentPage: params.page
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Pending Media List

export const pendingMediaList = (params) => (dispatch) => {
    // dispatch({ type: LOADING_UI })
    axios.post('feed/postlist.php', params)
        .then(res => {
            if (res.data.status === '1') {
                dispatch({
                    type: SET_PENDING_MEDIA,
                    payload: res.data.data,
                    hasmore: true
                });
            } else {
                dispatch({
                    type: SET_PENDING_MEDIA,
                    payload: [],
                    hasmore: false
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Flag Media Post

export const flagMedia = (params) => (dispatch) => {
    axios.post('feed/flagpost.php', params)
        .then(res => {
            if (res.data.status === '1') {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Media Detail

export const mediaDetail = (params) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('feed/getpostbyid.php', params)
        .then(res => {
            if (res.data.status === '1') {
                dispatch({
                    type: MEDIA_DETAIL,
                    payload: res.data.data.data
                });
            } else {
                dispatch({
                    type: MEDIA_DETAIL,
                    payload: {}
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Delete Article

export const deleteMedia = (params) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('feed/deletepost.php', params)
        .then(res => {
            if (res.data.status === '1') {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                dispatch({
                    type: DELETE_MEDIA,
                    payload: params.postid,
                });
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Article Detail

export const articleDetail = (params) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('feed/getarticlebyid.php', params)
        .then(res => {
            if (res.data.status === '1') {
                dispatch({
                    type: ARTICLE_DETAIL,
                    payload: res.data.data.data
                });
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Create Group

export const createGroup = (params, history) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('group/creategroup.php', params)
        .then(res => {
            if (res.data.status === '1') {
                history.push('patriot-group-and-request')
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Update Group

export const updateGroup = (params, history) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('group/editgroup.php', params)
        .then(res => {
            if (res.data.status === '1') {
                history.push('patriot-group-and-request')
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Delete Group

export const deleteGroup = (params, userid, isAdmin = false) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('group/deletegroup.php', params)
        .then(res => {
            if (res.data.status === '1') {
                const grpParam = {
                    apikey: process.env.REACT_APP_API_KEY,
                    userid: userid
                }
                if (isAdmin) {
                    $('#joinRequest').modal('hide');
                    dispatch(allPatriotsGroupsList(grpParam));
                } else {
                    dispatch(myGroupsList(grpParam));
                }
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Handle group requests

export const groupRequest = (params, authUser) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('group/handlegrouprequest.php', params)
        .then(res => {
            if (res.data.status === '1') {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                const grpParams = {
                    apikey: process.env.REACT_APP_API_KEY,
                    userid: authUser
                }
                dispatch({
                    type: GRP_REQUEST,
                    payload: params.groupid,
                });
                dispatch(myGroupsList(grpParams));
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Mute Unmute group

export const muteUnmuteGroup = (params) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('group/mutegroup.php', params)
        .then(res => {
            if (res.data.status === '1') {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                const grpParam = {
                    apikey: process.env.REACT_APP_API_KEY,
                    userid: params.userid
                }
                dispatch(myGroupsList(grpParam));
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Request join group

export const requestJoinGroup = (params) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('group/requestjoingroup.php', params)
        .then(res => {
            $('#joinRequest').modal('hide');
            if (res.data.status === '1') {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                dispatch({
                    type: GROUP_ACTION,
                    payload: params.groupid,
                });
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Accept group join request

export const acceptGroupJoinRequest = (params) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('group/acceptgroupjoinrequest.php', params)
        .then(res => {
            if (res.data.status === '1') {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Leave group

export const leaveGroup = (params) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('group/leavegroup.php', params)
        .then(res => {
            if (res.data.status === '1') {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                const grpParams = {
                    apikey: process.env.REACT_APP_API_KEY,
                    userid: params.userid
                }
                dispatch(myGroupsList(grpParams))
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// All groups list

export const allPatriotsGroupsList = (params) => (dispatch) => {
    // dispatch({ type: LOADING_UI })
    axios.post('group/allgroupslist.php', params)
        .then(res => {
            if (res.data.status === '1') {
                dispatch({
                    type: SET_GROUP,
                    payload: res.data.data
                });
            } else {
                dispatch({
                    type: SET_GROUP,
                    payload: []
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// My groups list

export const myGroupsList = (params) => (dispatch) => {
    // dispatch({ type: LOADING_UI })
    axios.post('group/mygroups.php', params)
        .then(res => {
            if (res.data.status === '1') {
                dispatch({
                    type: SET_MY_GROUP,
                    payload: res.data.data
                });
            } else {
                dispatch({
                    type: SET_MY_GROUP,
                    payload: []
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Group join requests list

export const joinGrpRequestersList = (params) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('group/groupjoinrequests.php', params)
        .then(res => {
            if (res.data.status === '1') {
                dispatch({
                    type: SET_REQUESTED_LIST,
                    payload: res.data.data
                });
            } else {
                dispatch({
                    type: SET_REQUESTED_LIST,
                    payload: []
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// All Patriot Users

export const allPatriotsUserList = (params) => (dispatch) => {
    axios.post('user/userlist.php', params)
        .then(res => {
            if (res.data.status === '1') {
                dispatch({
                    type: SET_PATRIOT_USER,
                    payload: res.data.data,
                    hasmore: res.data.data.length >= 10 ? true : false,
                    currentPage: params.page,
                    isSearch: params.search
                });
            } else {
                dispatch({
                    type: SET_PATRIOT_USER,
                    payload: [],
                    hasmore: false,
                    currentPage: params.page,
                    isSearch: params.search
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Initiate Freedom Call SMS to iOS

export const sendSms = (params) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('sendsms.php', params)
        .then(res => {
            if (res.status === 200) {
                console.log('Done')
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            console.log('Error-', error)
        });
}


// Initiate Freedom Call

export const initiateFreedomCall = (params, history) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('group/initiatecall.php', params)
        .then(res => {
            if (res.data.status === '1') {
                $('#initiateFreedom3').modal('hide');
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                let users = res.data.users;
                for (let i = 0; i < users.length; i++) {
                    if (users[i].os === 'iOS' && users[i].enable_sms === "1") {
                        const smsParam = {
                            to: users[i].phone,
                            body: 'Got Your 6 -' + res.data.username + ' Initiated Call for Freedom ' + `https://app.gotyour6app.net/user/notifications`
                        }
                        dispatch(sendSms(smsParam));
                    }
                }
                history.push('success');
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}


// User Detail
export const getUserDetail = (params, history) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('user/getuserbyid.php', params)
        .then(res => {
            if (res.data.status === '1') {
                dispatch({
                    type: SET_USER_DETAIL,
                    payload: res.data.data.user
                });
            } else {
                dispatch({
                    type: SET_USER_DETAIL,
                    payload: {}
                });
                toast.error('Invalid Detail found!', {
                    position: toast.POSITION.TOP_RIGHT
                });
                history.push('/')
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Flag User

export const flagUser = (params) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('user/flaguser.php', params)
        .then(res => {
            if (res.data.status === '1') {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Flagged Items

export const flaggedItems = (params) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('user/flaggeditems.php', params)
        .then(res => {
            if (res.data.status === '1') {
                dispatch({
                    type: SET_FLAGGED,
                    payload: res.data.data
                });
            } else {
                dispatch({
                    type: SET_FLAGGED,
                    payload: []
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Ignore Flag Item

export const ignoreFlagItem = (params) => (dispatch) => {
    axios.post('user/ignoreflaggeditem.php', params)
        .then(res => {
            if (res.data.status === '1') {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                const param = {
                    apikey: process.env.REACT_APP_API_KEY
                }
                dispatch(flaggedItems(param))
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// Delete Flag Item

export const deleteFlagItem = (params) => (dispatch) => {
    axios.post('user/deleteflaggeditem.php', params)
        .then(res => {
            if (res.data.status === '1') {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                const param = {
                    apikey: process.env.REACT_APP_API_KEY
                }
                dispatch(flaggedItems(param))
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}

// change Post Status (Approve or Deny)

export const changePostStatus = (params, typeId) => (dispatch) => {
    axios.post('feed/approvepost.php', params)
        .then(res => {
            if (res.data.status === '1') {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                if (typeId === 1) {
                    dispatch({
                        type: CHANGE_POST_STATUS,
                        payload: params.article_id,
                        postType: typeId
                    });
                }
                else if (typeId === 2) {
                    dispatch({
                        type: CHANGE_POST_STATUS,
                        payload: params.event_id,
                        postType: typeId
                    });
                }
                else if (typeId === 3) {
                    dispatch({
                        type: CHANGE_POST_STATUS,
                        payload: params.post_id,
                        postType: typeId
                    });
                }
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            dispatch({ type: CLEAR_ERRORS })
        }).catch(error => {
            if (error.response.status !== '1') {
                window.location.href = '/';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                dispatch({ type: CLEAR_ERRORS });
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
}
