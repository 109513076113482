import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser, deleteUserAccount, updateProfile } from '../../Redux/actions/userAction';

class Sidebar extends Component {

    handleLogout = () => {
        this.props.logoutUser();
    }

    render() {
        const { user: { user }, menuOpened } = this.props;
        return (
            <>
                <div className={menuOpened ? "outerLay open" : "outerLay"} onClick={this.props.menuOpenedToggle}></div>
                <div className={menuOpened ? "sidemenu scrollSection open" : "sidemenu scrollSection"}>
                    <div className="profile-block">
                        <div className="profileImage">
                            <figure>
                                <img src={user !== undefined && user.url !== "" ? process.env.REACT_APP_API_URL + user.url : '../images/logo.png'} alt="Fig" />
                            </figure>
                            <button className="addProfile">
                                <a href="update-profile">
                                    <img src="../images/add-profile-plus.svg" alt="Add" />
                                </a>
                            </button>
                        </div>
                        <span className="upload-title">{user !== undefined ? user.name : ''}</span>
                        <span className="upload-title">@{user !== undefined ? user.username : ''}</span>
                    </div>
                    <div className="menu scrollSection">
                        <ul>
                            <li><Link to="/"><i className="icon home-icon"></i> Home</Link></li>
                            <li><Link to="create-patriot-group"><i className="icon patriot-group-icon"></i> Create Patriots Group</Link></li>
                            {user !== undefined && user.admin === "1" &&
                                <>
                                    <li><Link to="users"><i className="icon user-icon"></i> Users</Link></li>
                                    <li><Link to="flagged-list"><i className="icon flag-icon"></i> Flagged</Link></li>
                                </>
                            }
                            <li><Link to="chat"><i className="icon chat-icon"></i> Chat</Link></li>
                            <li><Link to="notifications"><i className="icon notification-icon"></i> Notifications</Link></li>
                            <li><a href="https://www.gotyour6app.net/faq" target="_blank" rel="noreferrer"><i className="icon faq-icon"></i> FAQ</a></li>
                            <li><Link to="setting"><i className="icon setting-icon"></i> Settings</Link></li>
                            <li className="logout"><Link to="/login" onClick={this.handleLogout}><i className="icon logout-icon"></i> Log Out</Link></li>
                        </ul>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
})

const mapActionsToProps = {
    logoutUser,
    deleteUserAccount,
    updateProfile
}

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Sidebar));